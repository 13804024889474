export const bannerSizes = {
  fullGrid: {
    mobile: {
      height: 317,
      width: 351,
    },
    desktop: {
      height: 400,
      width: 1332,
    },
  },
  halfGrid: {
    mobile: {
      width: 351,
      height: 300,
    },
    desktop: {
      width: 660,
      height: 400,
    },
  },
  horizontalGrid: {
    mobile: {
      width: 172,
      height: 160,
    },
    desktop: {
      width: 400,
      height: 194,
    },
  },
  medium: {
    mobile: {
      width: 351,
      height: 288,
    },
    desktop: {
      width: 660,
      height: 572,
    },
  },
  quarterGrid: {
    desktop: {
      width: 1332,
      height: 400,
    },
  },
  small: {
    mobile: {
      width: 351,
      height: 180,
    },
    desktop: {
      width: 660,
      height: 280,
    },
  },
  verticalGrid: {
    mobile: {
      width: 351,
      height: 300,
    },
    desktop: {
      width: 248,
      height: 400,
    },
  },
  xs: {
    mobile: {
      width: 172,
      height: 149,
    },
    desktop: {
      width: 324,
      height: 280,
    },
  },
} as const;
