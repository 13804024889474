import styled from "@emotion/styled/macro";
import { MQ } from "../../styles/helpers";
import spaces from "../../styles/theme/spaces";

export const ComponentWrapper = styled.ul`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: ${spaces.mini};
  height: 100%;
  padding-left: 0;
  overflow-y: hidden;
  list-style-type: none;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  ${MQ("tablet")} {
    grid-gap: ${spaces.smallRegular};
  }
`;
