import { useMediaQuery } from "@mui/material";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import type {
  CountdownTimerSettings,
  Image as IconData,
  Product,
} from "@xxl/content-api";
import { default as React } from "react";
import { getImageUrl, handleImageError } from "../../../utils/xxl-image";
import {
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
} from "../../../utils/xxl-screen";
import { CountdownTimer } from "../../CountdownTimer";
import type { HighlightedProductBannerPricing } from "../../PriceDisplays/price-display-helper";
import { BannerSize } from "../../PriceDisplays/price-display-helper";
import {
  OtherPrice,
  OtherPriceDisclaimer,
  OtherPriceWrapper,
} from "../../PriceDisplays/PriceDisplays.styled";
import { CountdownTimeContainer } from "../Banner.styled";
import {
  CheapestPrice,
  PriceWrapper,
  ProductInfoWrapper,
  SmallBannerProductInfoWrapper,
} from "../ProductBanner/ProductBanner.styled";
import type { ImageSettings } from "../Shared/BannerContent/BannerContent.helper";
import {
  ProductBannerImageSettings,
  getImagePriority,
  getImageProp,
} from "../Shared/BannerContent/BannerContent.helper";
import type { Ribbon, SanityTextColor } from "../types";
import {
  BackgroundWrapper,
  Headline,
  Icon,
  Image,
  Label,
  Logo,
  ProductDescription,
  ProductName,
  SmallBannerImage,
  Subtitle,
} from "./HighlightedProductBanner.styled";
import { HighlightedPriceInfoRibbon } from "./PriceInfoRibbon/PriceInfoRibbon";

type HighlightedProductBannerProps = {
  isHighPrioComponent: boolean;
  priceData: HighlightedProductBannerPricing;
  size: BannerSize;
  textColor: SanityTextColor;
  backgroundColor?: string;
  cheapestPrice?: string;
  countdownTimerSettings?: CountdownTimerSettings | null;
  discountRibbon?: Ribbon;
  headline?: string;
  icon?: IconData;
  label?: string;
  logo?: IconData;
  product?: Product;
  productDescription?: string;
  productName?: string;
  subtitle?: string;
};

const HighlightedProductBanner = ({
  backgroundColor,
  countdownTimerSettings,
  headline,
  icon,
  label,
  logo,
  isHighPrioComponent,
  priceData,
  product,
  productDescription,
  productName,
  size,
  subtitle,
  textColor,
}: HighlightedProductBannerProps) => {
  const isMobile = useMediaQuery(mobileMediaQuery);
  const isMobileAndTablet = useMediaQuery(mobileAndTabletMediaQuery);
  const BannerImage = size === BannerSize.SMALL ? SmallBannerImage : Image;
  const imageSettings: ImageSettings = {
    ...getImagePriority(isHighPrioComponent),
    isMobile,
    sizes: ProductBannerImageSettings[size].sizes,
    srcSetSizes: ProductBannerImageSettings[size].srcSetSizes,
  };
  const image =
    product !== undefined
      ? {
          url: product.imageUrl,
          alt: productName,
        }
      : undefined;
  const backgroundImage = isNotNullOrUndefined(image)
    ? getImageProp(imageSettings, image)
    : undefined;
  const {
    prices: { alternate, selling, cheapestInRecentPast },
    ribbons,
  } = priceData;

  const ProductNameAndDesc = () => (
    <>
      {isNotNullOrUndefined(productName) && (
        <ProductName bannerSize={size}>{productName}</ProductName>
      )}
      {isNotNullOrUndefined(productDescription) && (
        <ProductDescription>{productDescription}</ProductDescription>
      )}
    </>
  );

  const MAX_LOGO_IMAGE_WIDTH_PX = 200;

  return (
    <BackgroundWrapper
      backgroundColor={backgroundColor}
      bannerSize={size}
      data-testid="highlighted-product-banner"
      viewport={isMobile ? "mobile" : "desktop"}
    >
      <ProductInfoWrapper textColor={textColor}>
        {isNotNullOrUndefined(label) && <Label>{label}</Label>}
        {isNotNullOrUndefined(headline) && (
          <Headline bannerSize={size}>{headline}</Headline>
        )}
        {isNotNullOrUndefined(subtitle) && <Subtitle>{subtitle}</Subtitle>}
        {countdownTimerSettings !== null &&
          countdownTimerSettings?.date !== undefined && (
            <CountdownTimeContainer>
              <CountdownTimer
                date={new Date(countdownTimerSettings.date)}
                isBlackText={countdownTimerSettings.isBlack}
                isOnlyHours={countdownTimerSettings.isOnlyHours}
                description={countdownTimerSettings.description}
              />
            </CountdownTimeContainer>
          )}
        {!isMobile && size === BannerSize.MEDIUM && <ProductNameAndDesc />}
      </ProductInfoWrapper>
      {!isMobileAndTablet && size === BannerSize.SMALL && (
        <SmallBannerProductInfoWrapper textColor={textColor}>
          <ProductNameAndDesc />
        </SmallBannerProductInfoWrapper>
      )}
      <PriceWrapper textColor={textColor}>
        {isNotNullOrUndefined(ribbons.discount) && (
          <HighlightedPriceInfoRibbon
            bannerSize={size}
            isDiscountInfo={true}
            label={ribbons.discount.label}
            colors={ribbons.discount.colors}
          />
        )}
        <HighlightedPriceInfoRibbon
          bannerSize={size}
          isDiscountInfo={false}
          label={selling.price}
          colors={ribbons.price.colors}
        />
        {alternate !== undefined && (
          <OtherPriceWrapper isSmall={size === BannerSize.SMALL}>
            <OtherPrice isInvertedPrice={true}>{alternate.price}</OtherPrice>
            &nbsp;
            <OtherPriceDisclaimer>{alternate.label}</OtherPriceDisclaimer>
          </OtherPriceWrapper>
        )}
        <CheapestPrice>{cheapestInRecentPast}</CheapestPrice>
      </PriceWrapper>
      {isNotNullOrUndefined(backgroundImage) && (
        <BannerImage
          data-private={true}
          className="highlighted-product-banner-image"
          sizes={backgroundImage.sizes}
          src={backgroundImage.url}
          srcSet={backgroundImage.srcSet}
          alt={backgroundImage.alt}
          fetchPriority={backgroundImage.fetchPriority}
          loading={backgroundImage.loading}
          onError={handleImageError}
        />
      )}

      {isNotNullOrUndefined(icon?.url) && (
        <Icon src={icon.url} alt={icon.alt} />
      )}
      {isNotNullOrUndefined(logo?.url) && (
        <Logo
          src={getImageUrl({ url: logo.url }, MAX_LOGO_IMAGE_WIDTH_PX)}
          alt={logo.alt}
        />
      )}
    </BackgroundWrapper>
  );
};

export default HighlightedProductBanner;
